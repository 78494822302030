.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.game {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 0.3em;
  padding-left: 2vw;
  padding-right: 2vw;
}

.game .cell {
  font-size: 3vmin;
  position: relative;
  border: 1px solid white;
  width: 2em;
  height: 2em;
  transition: background-color 0.1s ease-in;
}

.game .cell > input {
  box-sizing: border-box;
  background-color: transparent;
  font-size: inherit;
  border: 0px solid white;
  width: 2em;
  height: 2em;
  outline-offset: 0;
  padding: 0;
  color: inherit;

  text-align: center;
}

.game .cell > input:focus {
  outline: none;
  border-width: 1px;
}

.game .cell.-error {
  background-color: rgb(250, 125, 125);
}

.game .cell.-focus::before {
  display: inline;
  content: '';
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: rgba(255, 255, 255, 0.1);
}
